import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SecurityModal from "../common/SecurityModal";
import { history } from "util/history";
import { FormattedMessage, injectIntl } from "react-intl";
import { envConfig } from "env-config";
import _ from "lodash";

const mapStateToProps = state => {
    return {
        lockedAccountError: state.lockedAccount.error
    };
};
const region = envConfig.realm ? envConfig.realm : "";

const contactMap = {
    HK: {
        copy: {
            "en-HK": "Hong Kong support hotline",
            "zh-sim-HK": "香港支援热线",
            "zh-trd-HK": "香港支援熱線"
        },
        phone: "(852) 3604 0303",
        email: "digital.client.services.asia@hsbcpb.com"
    },
    SG: {
        copy: {
            "en-HK": "Singapore support hotline",
            "zh-sim-HK": "新加坡支援热线",
            "zh-trd-HK": "新加坡支援熱線"
        },
        phone: "(65) 6658 2707",
        email: "digital.client.services.asia@hsbcpb.com"
    }
};
class CommonLockedAccount extends Component {
    constructor(props) {
        super(props);
    }
    renderDisplayMessage(warningMsg) {
        const lang = this.props.intl.locale;
        const phoneTxt = contactMap[region] ? contactMap[region].phone : "";
        const eMailTxt = contactMap[region] ? contactMap[region].email : "";
        let HelpLineTxt = "";
        if (contactMap[region]) {
            HelpLineTxt =
                region === "HK" || region === "SG"
                    ? contactMap[region]["copy"][lang]
                    : contactMap["copy"];
        }
        const replaceHotLineTxt = _.replace(
            this.props.intl.formatMessage({
                id: warningMsg
            }),
            "{HelpLineTxt}",
            HelpLineTxt
        );
        const replacePhoneTxt = _.replace(
            replaceHotLineTxt,
            "{RM_PhoneNO}",
            phoneTxt
        );
        return _.replace(replacePhoneTxt, "{Emaill_Address}", eMailTxt);
    }
    render() {
        let titleIcon = <i className="icon lock error" />;
        let warningMsg;
        let confirmationBtnTitle;
        let onConfirmationClick;
        const errorCode =
            history.location.state && history.location.state.errorCode;
        let modalTitle = "commonLockedAccount.title";

        let modalAriaTitle = "commonLockedAccount.ariaTitle";
        const backToLogonText = "commonLockedAccount.label.backToLogon";
        const fullLockBackToLogonText = "fullLockedAccount.label.backToLogon";
        const unknownText = "commonLockedAccount.warning.unknown";
        const titleText = "commonLockedAccount.warning.noConnection.title";
        const noConnectionBackToLogonText =
            "commonLockedAccount.warning.noConnection.backToLogon";
        const registratedErrorText = "registration.error";

        switch (errorCode) {
            case "passwordLock":
                warningMsg = "commonLockedAccount.warning.passwordLock";
                confirmationBtnTitle = backToLogonText;
                onConfirmationClick = () => {
                    window.tealiumHub.ET004(backToLogonText);
                    history.push("/resetPassword");
                };
                window.tealiumHub.PT003();

                break;
            case "fullaccountLock":
                warningMsg = "fullLockedAccount.warning.detail";
                confirmationBtnTitle = fullLockBackToLogonText;
                onConfirmationClick = () => {
                    window.tealiumHub.ET005(fullLockBackToLogonText);
                    history.push("/logon");
                };
                window.tealiumHub.PT004();
                break;
            case "genericAccountLock":
                warningMsg = "reset.pwd.account.lock.warning.detail";
                onConfirmationClick = () => {
                    window.tealiumHub.ET005(fullLockBackToLogonText);
                    history.push("/logon");
                };
                window.tealiumHub.PT004();
                break;
            case "securityQuestionLock":
                warningMsg = "securityQuestionLock.warning.detail";
                confirmationBtnTitle = "securityQuestionLock.label.backToLogon";
                onConfirmationClick = () => history.push("/logon");

                break;
            case "Unkown":
                warningMsg = unknownText;
                modalTitle = unknownText;
                modalAriaTitle = unknownText;
                confirmationBtnTitle = backToLogonText;
                onConfirmationClick = () => history.push("/logon");
                window.tealiumHub.ET046(unknownText);
                break;
            case "noConnection":
                titleIcon = <i className="icon exclamation triangle error" />;
                warningMsg = "commonLockedAccount.warning.noConnection.detail";
                modalTitle = titleText;
                modalAriaTitle = titleText;
                confirmationBtnTitle = noConnectionBackToLogonText;
                onConfirmationClick = () => history.push("/logon");
                if (
                    window.location.href.indexOf("33") === -1 &&
                    window.location.href.indexOf("001") === -1
                ) {
                    window.tealiumHub.ET046(titleText);
                }
                break;
            case "genericWrongCredential":
                titleIcon = <i className="icon exclamation triangle error" />;
                warningMsg = "login.general.wrong.credential.content";
                modalTitle = "login.general.wrong.credential.title";
                modalAriaTitle = "login.general.wrong.credential.title";
                confirmationBtnTitle = noConnectionBackToLogonText;
                onConfirmationClick = () => history.push("/logon");
                break;
            case "globalGenericAccountLock":
                warningMsg = "generic.lock.account.warning.detail";
                modalTitle = "login.general.lock.account.title";
                confirmationBtnTitle =
                    "login.general.lock.account.to.reset.pwd";
                onConfirmationClick = () => {
                    window.tealiumHub.ET004(backToLogonText);
                    history.push("/resetPassword");
                };
                window.tealiumHub.PT004();
                break;
            case "registrationOtpError":
                titleIcon = <i className="icon exclamation triangle error" />;
                warningMsg = "registration.otp.error";
                modalTitle = registratedErrorText;
                modalAriaTitle = registratedErrorText;
                confirmationBtnTitle = noConnectionBackToLogonText;
                onConfirmationClick = () => history.push("/logon");
                break;
            case "registrationOtpErrorThreeTimes":
                titleIcon = <i className="icon exclamation triangle error" />;
                warningMsg = "registration.error.three.times";
                modalTitle = registratedErrorText;
                modalAriaTitle = registratedErrorText;
                confirmationBtnTitle = noConnectionBackToLogonText;
                onConfirmationClick = () => history.push("/logon");
                break;
            default: {
                history.push("/logon");
                return null;
            }
        }

        return (
            <SecurityModal
                title={modalTitle}
                ariaTitle={modalAriaTitle}
                titleIcon={titleIcon}
                onConfirmationClick={onConfirmationClick}
                confirmationBtnTitle={confirmationBtnTitle}
            >
                <CommonLockedAccountContainer>
                    {this.renderDisplayMessage(warningMsg)}
                </CommonLockedAccountContainer>
            </SecurityModal>
        );
    }
}

const CommonLockedAccountContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    width: 93%;
`;

export default connect(mapStateToProps)(injectIntl(CommonLockedAccount));
