import React, { Component } from "react";
import styled from "styled-components";
import { history } from "util/history";
import SecurityModal from "../common/SecurityModal";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        gvLogon: state.logon
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

class GvResult extends Component {
    render() {
        return (
            <div>
                <SecurityModal
                    title="gvResult.title"
                    ariaTitle="gvResult.ariaTitle"
                    onConfirmationClick={() => {
                        const formSelelctor = document.getElementById(
                            "link-profile-form"
                        );
                        formSelelctor.submit();
                    }}
                    confirmationBtnTitle="gvResult.label.backToPremier"
                >
                    <GvResultContainer>
                        <div className="gvresult-content">
                            <i className="icon check success" />
                            {this.props.intl.formatMessage({
                                id: "gvResult.content"
                            })}
                        </div>
                    </GvResultContainer>
                </SecurityModal>
                <form
                    id="link-profile-form"
                    action={this.props.gvLogon.url}
                    method="POST"
                    style={{ display: "none" }}
                >
                    <label>
                        <input
                            type="text"
                            name="SSO_TOKEN_ID"
                            value={this.props.gvLogon.RBtoken}
                        />
                    </label>
                    <input type="submit" value="Submit" onClick={this.submit} />
                </form>
            </div>
        );
    }
}

const GvResultContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    .success {
        color: #4faf00;
        margin-right: 4px !important;
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(GvResult));
