import TermsAndCondition from "./registrationConditions/T&C.js";
import ToU from "./registrationConditions/ToU.js";
import lang from "./lang.json";

export default {
    lang,
    registrationTerms: [
        {
            label: "Terms and Conditions",
            html: TermsAndCondition
        }
    ]
};
