import _ from "lodash";

export const languageActions = {
    hideSelectOption,
    updateLanguage,
    showMobileLanguageContainer
};

function updateLanguage(data) {
    return {
        type: "UPDATE_LANGUAGE_SESSION_SUCCESS",
        data
    };
}

function hideSelectOption(isHideLanguageSelect) {
    return {
        type: "HIDE_LANGUAGE_SELECT_OPTION",
        isHideLanguageSelect
    };
}

function showMobileLanguageContainer(isShowContianer) {
    return {
        type: "SHOW_LANGUAGE_CONTAINER",
        isShowContianer
    };
}
