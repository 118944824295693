import React, { Component } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import hsbcLogoeEn from "assets/image/hsbc_logo_rev.svg";
import hsbcLogoSimHk from "assets/image/hsbc_logo_sim_HK_rev.svg";
import hsbcLogoTrdHk from "assets/image/hsbc_logo_trd_HK_rev.svg";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        selectedLang: state.languageSession.selectedLang
    };
};
class LogoMark extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const selectChinese = this.props.selectedLang.indexOf("zh") !== -1;
        return (
            <LogoMarkContainer
                showLogo={this.props.isLoginPage}
                className="LogoMarkContainer"
                selectChinese={selectChinese}
            >
                <div className="logo">
                    <FormattedMessage id="inputCredential.top.title" />
                </div>
            </LogoMarkContainer>
        );
    }
}

const LogoMarkContainer = styled.div`
    display: ${props => (props.showLogo ? "block" : "none")};
    .logo {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 35px;
    }
`;
export default connect(mapStateToProps)(injectIntl(LogoMark));
