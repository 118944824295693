import React, { Component } from "react";
import styled from "styled-components";
import { color } from "util/color";
import { injectIntl } from "react-intl";
import { Link } from "hsbc-pb-sdk";
class CountDown extends Component {
    constructor(props) {
        super(props);
        this.countDown = null;
        this.isTouched = null;
        this.state = {
            showMsg: !props.shouldShowBtnAtTheBeginning,
            duration: props.duration,
            resentBtnHasBeenClicked: false,
            countdownButtonText: "countdown.requestButton"
        };
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval(this.countDown);
        this.isTouched = null;
    }

    requestNewCode = () => {
        window.tealiumHub.ET009(this.state.countdownButtonText);
        this.setState({
            resentBtnHasBeenClicked: true
        });
        if (this.props.isRealmCH) {
            const { clickFn, clearFn } = this.props;
            clearFn();
            clickFn();
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    showMsg: true
                };
            });
            if (this.isTouched === true) {
                const { clickFn, clearFn } = this.props;
                clearFn();
                clickFn();
                this.startTimer();
            }
        }
    };

    startTimer = () => {
        if (this.state.duration > 0) {
            this.countDown = setInterval(() => {
                let duration = this.state.duration;
                if (duration > 1) {
                    duration -= 1;
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            duration
                        };
                    });
                } else {
                    this.setState(prevState => {
                        clearInterval(this.countDown);
                        this.isTouched = true;
                        return {
                            ...prevState,
                            showMsg: false,
                            duration: this.props.duration
                        };
                    });
                }
            }, 1000);
        }
    };

    render() {
        const countMsg =
            this.props.intl.formatMessage({ id: "countdown.requestTip" }) +
            " " +
            this.state.duration +
            " " +
            this.props.intl.formatMessage({
                id: "countdown.requestTip.seconds"
            });
        let msgInFr = false;
        if (countMsg.indexOf("Vous pouvez") >= 0) {
            msgInFr = true;
        }
        let buttonComp;
        if (this.props.isRealmCH) {
            buttonComp = (
                <>
                    <Link
                        className={`otp-request ${
                            this.props.otpType === "EMAIL" &&
                            !this.state.resentBtnHasBeenClicked
                                ? "otp-request-only"
                                : ""
                        }`}
                        icon={true}
                        alignment="right"
                        type="s4"
                        size="small"
                        text={this.props.intl.formatMessage({
                            id: this.state.countdownButtonText
                        })}
                        onClick={() => this.requestNewCode()}
                    />
                    {this.state.resentBtnHasBeenClicked &&
                        this.props.compShownAfterResentClicked}
                </>
            );
        } else {
            if (this.state.showMsg) {
                let props;
                if ([60, 30, 10].indexOf(this.state.duration) >= 0) {
                    props = {
                        role: "alert"
                    };
                    if (!this.isTouched) {
                        this.isTouched = true;
                    }
                } else {
                    props = {};
                }

                buttonComp = (
                    <p
                        className={`p-line--height ${
                            msgInFr ? "fr-error--space" : ""
                        }`}
                        {...props}
                    >
                        {countMsg}
                    </p>
                );
            } else {
                buttonComp = (
                    <>
                        <Link
                            className={`otp-request ${
                                this.props.otpType === "EMAIL" &&
                                !this.state.resentBtnHasBeenClicked
                                    ? "otp-request-only"
                                    : ""
                            }`}
                            icon={true}
                            alignment="right"
                            type="s4"
                            size="small"
                            text={this.props.intl.formatMessage({
                                id: this.state.countdownButtonText
                            })}
                            onClick={() => this.requestNewCode()}
                        />
                        {this.state.resentBtnHasBeenClicked &&
                            this.props.compShownAfterResentClicked}
                    </>
                );
            }
        }

        return (
            <CountContent otpType={this.props.otpType}>
                {buttonComp}
            </CountContent>
        );
    }
}

CountDown.defaultProps = {
    startNow: true
};

const CountContent = styled.div`
    color: ${color.text_color_dark};

    .otp-request {
        margin-top: 12px;
    }
    .otp-request-only {
        margin-bottom: 28px;
    }

    p {
        margin-top: 0px;
        margin-bottom: ${p => (p.otpType === "EMAIL" ? "28px" : "0px")};
        /* responsive styles ctrl in EnterOTP.js  */
        &.fr-error--space {
            @media (min-width: 769px) {
                line-height: 30px !important;
                height: 30px !important;
                width: 70%;
            }
        }
        &.p-line--height {
            @media (max-width: 768px) {
                width: auto;
            }
            @media (min-width: 769px) {
                line-height: 46px;
                height: 46px;
            }
        }
    }
    .link--emailOTP-entry {
        margin-top: 20px;
    }
    /* rm the default scale up effect of Link */
    .otp-request.hsbc-ui.link.s4,
    .link--emailOTP-entry.hsbc-ui.link.s4 {
        font-size: 14.256px;
        line-height: 1.688;
        @media only screen and (min-width: 1024px) {
            font-size: 14.256px;
            line-height: 1.688;
        }
    }

    .note--emailOTP-resent {
        position: relative;
        margin-top: 25px;
        font-size: 12px;
        color: black;
        font-weight: 300;
        line-height: 16px;
        .exclamation.triangle.error {
            position: relative;
            top: -2px;
            color: #af000d;
            font-size: 14px;
            padding-right: 8px;
            line-height: 20px;
        }
    }
`;

export default injectIntl(CountDown);
