import React, { Component } from "react";
import _ from "lodash";
import InputCredential from "components/Logon/InputCredential";
import GenericOTP from "components/OTPVerify/GenericOTP";
import AcceptTerms from "components/Logon/AcceptTerms";
import CheckMobile from "components/Logon/CheckMobile";
import { changeIdleTimeOutValid } from "util/service";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { languageActions } from "actions/languageAction";
import { sendCodeIsLoading } from "actions/sendCodeAction";

const mapStateToProps = state => {
    return {
        error: state.logon.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendCodeIsLoading: payload => dispatch(sendCodeIsLoading(payload)),
        hideSelectOption: isHideLanguageSelect =>
            dispatch(languageActions.hideSelectOption(isHideLanguageSelect))
    };
};

class Logon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: ""
        };
        this.mappedStepToComponent = this.mappingStepToComponent();
        this.loadedComponents = {};
    }

    mappingStepToComponent = () => {
        return [
            {
                step: "INPUT_CREDENTIAL",
                component: (
                    <InputCredential
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                        history={this.props.history}
                        match={this.props.match}
                        hideSelectOption={this.props.hideSelectOption}
                    />
                )
            },
            {
                step: "GENERIC_OTP",
                component: (
                    <GenericOTP
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            },
            {
                step: "ACCEPT_TERMS",
                component: (
                    <AcceptTerms
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            },
            {
                step: "CHECK_MOBILE",
                component: (
                    <CheckMobile
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            }
        ];
    };

    componentDidMount() {
        this.props.sendCodeIsLoading(false);
        this.props.afterLoad(this.loadedComponents, this);
        this.jumpTo("INPUT_CREDENTIAL");
        changeIdleTimeOutValid(false);

        localStorage.setItem("guid", "");
        sessionStorage.removeItem("counter");
        sessionStorage.removeItem("isOtpError");
        sessionStorage.removeItem("usrname");
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentStep !== this.state.step) {
            this.setState((prevState, props) => {
                return { ...prevState, step: nextProps.currentStep };
            });
        }
    }

    processJsonData = (jsonData, actionContext, clientContext) => {
        clientContext.jsonData = jsonData;
        return Promise.resolve(
            window.com.ts.mobile.sdk.JsonDataProcessingResult.create(true)
        );
    };

    storeThis = (name, that) => {
        this.loadedComponents[name] = that;
    };

    jumpTo = step => {
        const renderedComponent = _.find(
            this.mappedStepToComponent,
            item => step === item.step
        );
        this.setState({
            renderedComponent: renderedComponent.component
        });
    };

    render() {
        return (
            <div style={{ zIndex: "1" }}>{this.state.renderedComponent}</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Logon));
