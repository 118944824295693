import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Notification } from "hsbc-pb-sdk";

const NoticeMessageContainer = styled.div`
    height: 58px;
    width: 100%;
    display: table;
    .hsbc-ui.notification {
        color: rgb(51, 51, 51);
        font-size: 18px;
        span {
            font-family: "Univers Next for HSBC";
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            margin-left: 5px;
        }
    }
`;

const NoticeMessage = props => {
    const { msgText, color } = props;
    if (color === "yellow") {
        return (
            <NoticeMessageContainer className="block--notice-msg">
                <Notification
                    warning
                    contextual
                    content={<FormattedMessage id={msgText} />}
                />
            </NoticeMessageContainer>
        );
    } else if (color === "red") {
        return (
            <NoticeMessageContainer className="block--notice-msg">
                <Notification
                    danger
                    contextual
                    content={<FormattedMessage id={msgText} />}
                />
            </NoticeMessageContainer>
        );
    } else {
        return (
            <NoticeMessageContainer className="block--notice-msg">
                <Notification
                    success
                    contextual
                    content={<FormattedMessage id={msgText} />}
                />
            </NoticeMessageContainer>
        );
    }
};

export default NoticeMessage;
