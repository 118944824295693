import React, { Component } from "react";
import styled from "styled-components";
import { envConfig } from "env-config";
import { color } from "util/color";
import { injectIntl } from "react-intl";
import { Button, Radio } from "hsbc-pb-sdk";
import { languageActions } from "actions/languageAction";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        isShowContianer: state.languageSession.isShowContianer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateLanguage: (languageSlectList, locale) =>
            dispatch(languageActions.updateLanguage(languageSlectList, locale)),
        showMobileLanguageContainer: isShowContianer =>
            dispatch(
                languageActions.showMobileLanguageContainer(isShowContianer)
            )
    };
};

class LanguageSelectContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value:
                this.props.selectedLang && this.props.selectedLang !== ""
                    ? this.props.selectedLang
                    : "en-HK"
        };
    }
    handleChange = (e, value) => {
        this.setState({ value });
    };
    promiseUpdateLanguage = result => {
        const updateFunc = this.props.updateLanguage;
        const promise = new Promise((resolutionFunc, rejectionFunc) => {
            updateFunc(result);
            resolutionFunc("yes");
        });
        return promise;
    };
    updateLanguage = () => {
        this.promiseUpdateLanguage(this.state.value).then(
            this.props.showMobileLanguageContainer("false")
        );
    };
    closeContainer = () => {
        this.props.showMobileLanguageContainer("false");
    };
    render() {
        const items = [];
        envConfig.languageSlectList &&
            envConfig.languageSlectList.map(item => {
                items.push({
                    label: item.text,
                    value: item.locale
                });
            });
        return (
            <LanguageSelectBox isshowcontianer={this.props.isShowContianer}>
                <div
                    className="languageContinerOverLay"
                    isshowcontianer={this.props.isShowContianer}
                ></div>
                <div
                    className="languageContiner"
                    isshowcontianer={this.props.isShowContianer}
                >
                    <div className="title-box">
                        <div style={{ width: "30px" }}></div>
                        <div className="text-box">
                            <div className="text-pull"></div>
                            <p className="language-title">
                                {this.props.intl.formatMessage({
                                    id: "select.language.label"
                                })}
                            </p>
                        </div>

                        <div
                            role="botton"
                            tabIndex={0}
                            onClick={this.closeContainer}
                            data-testid="button-close"
                        >
                            <i className="icon close close-button"></i>
                        </div>
                    </div>
                    <Radio.Group
                        name="LanguageGroup"
                        items={items}
                        active={this.state.value}
                        handleChange={this.handleChange}
                        className="language-radio-group"
                    />
                    <Button
                        data-testid="button-update"
                        label={this.props.intl.formatMessage({
                            id: "select.language.done.label"
                        })}
                        className="language-done-buton"
                        onClick={this.updateLanguage}
                    />
                </div>
            </LanguageSelectBox>
        );
    }
}

const LanguageSelectBox = styled.div`
    .languageContinerOverLay {
        width: 100%;
        height: 100%;
        background-color: #000;
        filter: alpha(opacity=50);
        -moz-opacity: 0.5;
        opacity: 0.5;
        position: absolute;
        left: 0px;
        top: 0px;
        /* display: none; */
        display: ${props =>
            props.isshowcontianer && props.isshowcontianer === "true"
                ? "block"
                : "none"};
        z-index: 1000;
    }
    .languageContiner {
        width: 100%;
        display: ${props =>
            props.isshowcontianer && props.isshowcontianer === "true"
                ? "flex"
                : "none"};
        position: fixed;
        bottom: 0px;
        rigth: 0px;
        background-color: #ffffff;
        z-index: 1500;
        flex-direction: column;
        border-radius: 4px 0 0 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        .language-radio-group {
            margin-bottom: 20px;
            .radio {
                border: 1px solid #f3f3f3;
                padding-left: 20px;
            }
        }

        .language-done-buton {
            background-color: ${color.hsbc_red};
            color: ${color.white};
            border: 0px;
            width: 85%;
            margin-left: 2rem;
            margin-bottom: 20px;
        }
        .title-box {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .text-box {
                color: #545454;
                font-size: 19px;
                letter-spacing: 0;
                line-height: 23px;
                text-align: center;
                flex: 1;
                position: relative;
                .text-pull {
                    height: 4px;
                    width: 40px;
                    border-radius: 4px;
                    background-color: rgba(178, 178, 178, 0.5);
                    position: absolute;
                    top: 9px;
                    left: 43%;
                }
            }
            .language-title {
            }

            .close-button {
                color: #545454;
                line-height: 61px;
                width: 30px;
            }
        }
    }

    .hsbc-ui.radio-group legend {
        display: none;
    }
`;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LanguageSelectContainer));
