import React, { Component } from "react";
import styled from "styled-components";
import { history } from "util/history";
import SecurityModal from "../common/SecurityModal";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        gvLogon: state.logon
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

class GvSSOError extends Component {
    render() {
        return (
            <GVSSOErrorContainer>
                <SecurityModal
                    ariaTitle="gvError.ariaTitle"
                    titleIcon={
                        <i className="icon exclamation triangle error gv-icon-margin" />
                    }
                    onConfirmationClick={() => {
                        window.close();
                    }}
                    confirmationBtnTitle="gvResult.label.closeWindow"
                >
                    <GvResultContainer>
                        <div className="gvresult-content">
                            {this.props.intl.formatMessage({
                                id: "gvResult.error.sso.content"
                            })}
                        </div>
                    </GvResultContainer>
                </SecurityModal>
            </GVSSOErrorContainer>
        );
    }
}
const GVSSOErrorContainer = styled.div`
    .gv-icon-margin {
        margin-top: 38px;
        font-size: 24px !important;
    }
`;
const GvResultContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    .success {
        color: #4faf00;
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(GvSSOError));
