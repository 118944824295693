import React, { Component } from "react";
import styled from "styled-components";

import { securityQuestionCreateValidate } from "util/validator";
import { color } from "util/color";
import SecurityModal from "components/common/SecurityModal";
import SecurityTextInput from "components/common/SecurityTextInput";
import WarningMsg from "components/common/WarningMsg";
import Dropdown from "components/common/Dropdown";
import { connect } from "react-redux";
import _ from "lodash";
import { securityQuestionListActions } from "actions/createSecurityQuestionAction";
import { injectIntl } from "react-intl";

const mapStateToProps = state => {
    return {
        securityQuestionList: state.securityQuestionList.securityQuestionList,
        userName: state.createUsername.data.userName,
        disableButton: state.securityQuestionList.securityQuestionBtn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSecurityQuestionListSuccess: questionsArray => {
            dispatch({
                type: "SECURITY_QUESTION_LIST_SUCCESS",
                data: questionsArray
            });
        },
        securityQuestionBtn: payload =>
            dispatch(securityQuestionListActions.securityQuestionBtn(payload))
    };
};

class createSecurityQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question1: null,
            answer1: null,
            question2: null,
            answer2: null,
            questionList1: null,
            questionList2: null
        };
        this.__questionList1Comp = null;
        this.__questionList2Comp = null;
        if (props.storeThis) {
            props.storeThis("createSecurityQuestions", this);
        }
    }

    componentDidMount() {
        window.tealiumHub.PT013();
    }

    onChange = event => {
        const { target } = event;
        const { value, name } = target;
        this.setState((previousState, currentProps) => {
            return {
                ...previousState,
                [name]: value
            };
        });
    };

    validateAnswer1 = () => {
        const { answer1 } = this.state;
        const validateResult = securityQuestionCreateValidate(answer1, {
            userName: this.props.userName
        });
        this.setState(previousState => {
            return {
                ...previousState,
                answer1WarningMsg: validateResult,
                systemWarningMsg: null
            };
        });
    };
    validateAnswer2 = () => {
        const { answer2 } = this.state;
        const validateResult = securityQuestionCreateValidate(answer2, {
            userName: this.props.userName
        });
        this.setState(previousState => {
            return {
                ...previousState,
                answer2WarningMsg: validateResult,
                systemWarningMsg: null
            };
        });
    };
    onQuestion1Select = e => {
        window.tealiumHub.ET028("createSecurityQuestions.label.firstQuestion");
        const selectedOption = _.find(this.props.securityQuestionList, {
            _id: e.target.value.toString()
        });
        const securityQuestionList2 = _.filter(
            this.props.securityQuestionList,
            item => {
                return String(item._id) !== String(e.target.value);
            }
        );
        this.setState(previousState => {
            return {
                ...previousState,
                securityQuestionList2: securityQuestionList2,
                question1: selectedOption
            };
        });
        this.__questionList1Comp.toggleSelect();
    };
    onQuestion2Select = e => {
        window.tealiumHub.ET028("createSecurityQuestions.label.secondQuestion");
        const selectedOption = _.find(this.props.securityQuestionList, {
            _id: e.target.value.toString()
        });
        const securityQuestionList1 = _.filter(
            this.props.securityQuestionList,
            item => {
                return String(item._id) !== String(e.target.value);
            }
        );

        this.setState(previousState => {
            return {
                ...previousState,
                securityQuestionList1: securityQuestionList1,
                question2: selectedOption
            };
        });
        this.__questionList2Comp.toggleSelect();
    };
    successCallback = res => {
        this.props.jumpTo("REGISTRATED_SUCCESSFUL");
    };
    failCallback = res => {
        this.setState(previousState => {
            return {
                ...previousState,
                systemWarningMsg: res.statusText
            };
        });
    };

    componentWillReceiveProps(props, next) {
        const questionList1 = [],
            questionList2 = [];
        props.securityQuestionList.map(item => {
            if (item._text.indexOf("F-") !== -1) {
                questionList1.push(item);
            } else {
                questionList2.push(item);
            }
        });
        this.setState({
            questionList1,
            questionList2
        });
    }

    submit = () => {
        window.tealiumHub.ET030("registration.submit");
        const {
            question1,
            answer1,
            question2,
            answer2,
            answer1WarningMsg,
            answer2WarningMsg
        } = this.state;
        let systemWarningMsg = null;
        if (!question1) {
            systemWarningMsg = this.props.intl.formatMessage({
                id: "createSecurityQuestions.warning.firstQuestion"
            });
        } else if (!answer1) {
            systemWarningMsg = this.props.intl.formatMessage({
                id: "createSecurityQuestions.warning.firstAnswer"
            });
        } else if (!question2) {
            systemWarningMsg = this.props.intl.formatMessage({
                id: "createSecurityQuestions.warning.secondQuestion"
            });
        } else if (!answer2) {
            systemWarningMsg = this.props.intl.formatMessage({
                id: "createSecurityQuestions.warning.secondAnswer"
            });
        }
        this.setState(previousState => {
            return {
                ...previousState,
                systemWarningMsg: systemWarningMsg
            };
        });
        if (!systemWarningMsg && !answer1WarningMsg && !answer2WarningMsg) {
            const quesion1Obj = window.com.ts.mobile.sdk.SecurityQuestionAndAnswer.createAnswerToQuestion(
                this.state.question1,
                window.com.ts.mobile.sdk.SecurityQuestionAnswer.createWithText(
                    this.state.answer1
                )
            );
            const quesion2Obj = window.com.ts.mobile.sdk.SecurityQuestionAndAnswer.createAnswerToQuestion(
                this.state.question2,
                window.com.ts.mobile.sdk.SecurityQuestionAnswer.createWithText(
                    this.state.answer2
                )
            );

            const qa = [quesion1Obj, quesion2Obj];
            const inputResponse = window.com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                window.com.ts.mobile.sdk.SecurityQuestionInputResponse.createSecurityQuestionAnswersInputResponse(
                    qa
                )
            );
            this.props.securityQuestionBtn(true);
            this.acceptFN(inputResponse);
        }
    };
    render() {
        const {
            answer1WarningMsg,
            answer2WarningMsg,
            systemWarningMsg
        } = this.state;
        const answer1ErrorComp = answer1WarningMsg ? (
            <WarningMsg id="answer1_error" warningMsg={answer1WarningMsg} />
        ) : null;
        const answer2ErrorComp = answer2WarningMsg ? (
            <WarningMsg id="answer2_error" warningMsg={answer2WarningMsg} />
        ) : null;
        const systemErrorComp = systemWarningMsg ? (
            <WarningMsg id="system_error" warningMsg={systemWarningMsg} />
        ) : null;
        return (
            <SecurityModal
                title="createSecurityQuestions.title"
                ariaTitle="createSecurityQuestions.aria.title"
                onConfirmationClick={this.submit}
                confirmationBtnTitle="registration.submit"
                disabledButton={this.props.disableButton || false}
            >
                <CreateSecurityQuestionsContainer>
                    <p
                        className="content--p1"
                        aria-label={this.props.intl.formatMessage({
                            id: "createSecurityQuestions.aria.label.subTitle"
                        })}
                    >
                        {this.props.intl.formatMessage({
                            id: "validator.securityQuestion.message"
                        })}
                    </p>

                    <Dropdown
                        optionList={
                            this.state.questionList1 ||
                            this.state.securityQuestionList1 ||
                            this.props.securityQuestionList
                        }
                        label="createSecurityQuestions.label.firstQuestion"
                        onOptionSelect={e => this.onQuestion1Select(e)}
                        selectedOption={this.state.question1}
                        onRef={comp => (this.__questionList1Comp = comp)}
                        toggleSelectCallBack={bool =>
                            window.tealiumHub.ET027(
                                "createSecurityQuestions.label.firstQuestion",
                                bool ? "expanded" : "collapsed"
                            )
                        }
                    />

                    <SecurityTextInput
                        customClassName="createSecurityQuestions-input"
                        name="answer1"
                        label="createSecurityQuestions.label.answer"
                        hideLabel={false}
                        id="firstAnswer"
                        ariaDescribedby="answer1_error"
                        title="createSecurityQuestions.label.answer"
                        onBlur={this.validateAnswer1}
                        onChange={this.onChange}
                        errorComp={answer1ErrorComp}
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET029(
                                    "createSecurityQuestions.label.answer1"
                                );
                            }
                        }}
                    />

                    <Dropdown
                        optionList={
                            this.state.questionList2 ||
                            this.state.securityQuestionList2 ||
                            this.props.securityQuestionList
                        }
                        label="createSecurityQuestions.label.secondQuestion"
                        onOptionSelect={e => this.onQuestion2Select(e)}
                        selectedOption={this.state.question2}
                        onRef={comp => (this.__questionList2Comp = comp)}
                        toggleSelectCallBack={bool =>
                            window.tealiumHub.ET027(
                                "createSecurityQuestions.label.secondQuestion",
                                bool ? "expanded" : "collapsed"
                            )
                        }
                    />

                    <SecurityTextInput
                        customClassName="createSecurityQuestions-input"
                        name="answer2"
                        label="createSecurityQuestions.label.answer"
                        hideLabel={false}
                        id="secondAnswer"
                        ariaDescribedby="answer2_error"
                        title="createSecurityQuestions.label.answer"
                        onBlur={this.validateAnswer2}
                        onChange={this.onChange}
                        errorComp={answer2ErrorComp}
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET029(
                                    "createSecurityQuestions.label.answer2"
                                );
                            }
                        }}
                    />
                    <div>{systemErrorComp}</div>
                </CreateSecurityQuestionsContainer>
            </SecurityModal>
        );
    }

    startSession = () => {
        console.log("startSession");
    };
    endSession = () => {
        console.log("endSession");
    };
    setInputStep(stepNumber, maxNumber, stepDescription) {
        const currentStep = stepDescription;
        const questionsList = currentStep.getSecurityQuestions();
        questionsList.map(a => {
            a.translated = this.props.intl.formatMessage({
                id: `SecurityQuestion.Question.${a._id}`
            });
        });
        this.props.getSecurityQuestionListSuccess(questionsList);
    }
    promiseInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };
    setPromiseHandlers = (acceptFN, rejectFN) => {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    };
    onContinue = () => {
        console.log("onContinue");
    };
    onError = () => {
        window.globalHistory.push({
            pathname: "/globalError/noConnection?step=017",
            state: { errorCode: "noConnection" }
        });
    };
    promiseRecoveryForError = () => {
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
        );
    };
}

const CreateSecurityQuestionsContainer = styled.div`
    padding-bottom: 30px;
    input {
        height: 40px;
        color: ${color.text_color_dark};
    }
    .content--p1 {
        margin-top: 30px;
        line-height: 24px;
    }
    .label--question {
        margin-top: 22px;
        margin-bottom: 8px;
    }
    .security-input-label {
        margin-top: 25px;
        margin-bottom: 8px;
    }
    @media (min-width: 1366px) {
        .hsbc-ui.input {
            max-width: 100%;
        }
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(createSecurityQuestions));
